import React from "react"
import sliceIcon from "../images/icons/fflh-dp-icon-slice.svg"
import startoverIcon from "../images/icons/fflh-dp-icon-startover.svg"
import undoIcon from "../images/icons/fflh-dp-icon-undo.svg"

export default function Controls({
  startOver,
  undo,
  previewSlices,
  slicePizza,
  start,
}) {
  return (
    <div className="control_container row">
      <button
        onClick={slicePizza}
        className={`control-button_img ${start ? "hidden" : ""}`}
      >
        <img src={sliceIcon} className="control-button_img_icon" />
        <span className="control-button_img_text">Dela pizza</span>
      </button>
      <button
        onClick={undo}
        className={`control-button_img ${start ? "hidden" : ""}`}
      >
        <img src={undoIcon} className="control-button_img_icon" />
        <span className="control-button_img_text">Ångra senaste</span>
      </button>
      <button onClick={startOver} className="control-button_img">
        <img src={startoverIcon} className="control-button_img_icon" />
        <span className="control-button_img_text">Börja om</span>
      </button>
      <button
        onClick={previewSlices}
        className={`c-button c-button--purple ${start ? "hidden" : ""}`}
      >
        <span className="c-button__text">Förhandsgranska</span>
      </button>
    </div>
  )
}
