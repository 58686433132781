import React, { useEffect, useRef, useState } from "react"
import html2canvas from "html2canvas"
import Controls from "../../../../components/pizza-controls"
import Overlay from "../../../../components/pizza-overlay"
import pizzaImg from "../../../../images/vad-ar-rattvisa/fflh-dp-vadarrattvisa-delapizza-pizza.png"
import boardImg from "../../../../images/vad-ar-rattvisa/fflh-dp-vadarrattvisa-delapizza-platewood.png"
import { Helmet } from "react-helmet"

export default function Index() {
  const canvasBottomRef = useRef(null)
  const canvasTopRef = useRef(null)
  const canvasContainerRef = useRef(null)
  const offscreenCanvasRef = useRef(null)
  const screenshotRef = useRef(null)
  const [toggleOverlay, setToggleOverlay] = useState(true)
  const [start, setStart] = useState(false)
  const [lines] = useState([])
  const [angles, setAngles] = useState([])
  const [assigned, setAssigned] = useState(null)
  const [ctxT, setctxT] = useState(null)
  const [cx, setCx] = useState(null)
  const [cy, setCy] = useState(null)
  const [images, setImages] = useState([])
  const [canvasSize, setCanvasSize] = useState(null)
  const [loading, setLoading] = useState(true)
  const [people] = useState([
    {
      name: "cilla",
      pizza: [],
    },
    {
      name: "doris",
      pizza: [],
    },
    {
      name: "elmer",
      pizza: [],
    },
    {
      name: "amira",
      pizza: [],
    },
    {
      name: "ben",
      pizza: [],
    },
  ])

  const radius = canvasSize / 2 - 10
  let lastCanvasSize = null

  useEffect(() => {
    if (!loading) {
      //initiate canvases and context
      const canvasB = canvasBottomRef.current
      const canvasT = canvasTopRef.current
      const ctxB = canvasB.getContext("2d")
      const ctxT = canvasT.getContext("2d")
      setctxT(ctxT)
      canvasB.width = canvasSize
      canvasB.height = canvasSize
      canvasT.width = canvasSize
      canvasT.height = canvasSize
      setCx(canvasT.width / 2)
      setCy(canvasT.height / 2)

      //set the pizza image on the bottom canvas
      addPizzaImage(ctxB)
      canvasT.onclick = e => drawLine(e, ctxT)
    }
  }, [loading])

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", resizeHandler)
      resizeHandler()
    }
  }, [])

  const resizeHandler = () => {
    let width = window.innerWidth
    let size = 380
    if (width >= 1200) size = 500
    else if (width < 1200 && width >= 870) size = 400
    else if (width < 870 && width > 768) size = 395
    if (lastCanvasSize !== size) {
      if (images.length > 0) {
        window.location.reload()
      }
      setLoading(true)
      setCanvasSize(size)
      setLoading(false)
      lastCanvasSize = size
    }
  }

  //calculate height/width ratio
  //draw image on bottom canvas
  const addPizzaImage = ctx => {
    const imgObj = new Image()

    imgObj.onload = () => {
      let width = canvasBottomRef.current.width
      let naturalWidth = imgObj.naturalWidth
      let naturalHeight = imgObj.naturalHeight
      let ratio = naturalWidth / naturalHeight
      let height = width / ratio
      canvasBottomRef.current.height = height
      ctx.drawImage(imgObj, 0, 0, width, height)
    }
    imgObj.src = pizzaImg
  }

  //draw dotted line from click point to the middle on click
  const drawLine = (e, ctx) => {
    if (!start) {
      const canvas = canvasTopRef.current
      ctx.beginPath()
      ctx.strokeStyle = "black"
      ctx.setLineDash([5, 10])
      ctx.lineWidth = 3

      //correct mouse coords
      let rect = canvas.getBoundingClientRect()
      let x =
        ((e.clientX - rect.left) / (rect.right - rect.left)) * canvas.width
      let y =
        ((e.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height
      ctx.moveTo(x, y)
      lines.push({ x: x, y: y })

      //ctx.moveTo(e.offsetX, e.offsetY);
      //lines.push({x: e.offsetx, y: e.offsetY});

      ctx.lineTo(canvas.width / 2, canvas.height / 2)
      ctx.closePath()
      ctx.stroke()
    }
  }

  //draw full lines to preview how the pizzaslices will look.
  const previewSlices = (width, style) => {
    convertToAngles()
    const ctx = ctxT
    ctx.setLineDash([])
    ctx.lineWidth = width
    ctx.strokeStyle = style

    let startAngle = angles[0]

    angles.forEach((angle, index) => {
      let endAngle = angles[index + 1]
      ctx.beginPath()
      ctx.moveTo(cx, cy)
      ctx.arc(cx, cy, radius, startAngle, endAngle, false)
      ctx.lineTo(cx, cy)
      ctx.closePath()
      ctx.stroke()

      startAngle = endAngle
    })

    ctx.beginPath()
    ctx.moveTo(cx, cy)
    ctx.arc(cx, cy, radius, angles[angles.length - 1], angles[0], false)
    ctx.lineTo(cx, cy)
    ctx.closePath()
    ctx.stroke()
  }

  //convert coordinates to angles
  const convertToAngles = () => {
    setAngles([])
    lines.map((line, i) => {
      let angle = Math.atan2(line.y - cy, line.x - cx)
      angles.push(angle)
    })
    angles.sort((a, b) => a - b)
  }

  //show wich slice the user selected with a white dotted line.
  const selectSlice = ev => {
    const ctx = ctxT

    const x = ev.offsetX
    const y = ev.offsetY

    let coordsAngle = Math.atan2(y - cy, x - cx)
    let slice

    angles.map((angle, index) => {
      if (coordsAngle > angle && coordsAngle < angles[index + 1]) {
        slice = {
          cx: cx,
          cy: cy,
          radius: radius,
          startAngle: angle,
          endAngle: angles[index + 1],
        }
      }
    })
    if (!slice) {
      slice = {
        cx: cx,
        cy: cy,
        radius: radius,
        startAngle: angles[angles.length - 1],
        endAngle: angles[0],
      }
    }

    //exclude already assigned slices.
    let stop

    people.some(person => {
      if (person.pizza.length > 0) {
        person.pizza.some(pizzaObj => {
          if (
            pizzaObj.startAngle === slice.startAngle &&
            pizzaObj.endAngle === slice.endAngle
          ) {
            stop = true
          }
        })
      }
    })

    if (stop) return

    //draw normal lines for non selected slices, white for selected one.
    drawLines()
    ctx.lineWidth = 3
    ctx.setLineDash([])
    ctx.strokeStyle = "white"

    ctx.beginPath()
    ctx.moveTo(cx, cy)
    ctx.arc(
      slice.cx,
      slice.cy,
      slice.radius,
      slice.startAngle,
      slice.endAngle,
      false
    )
    ctx.lineTo(cx, cy)
    ctx.stroke()
    ctx.closePath()

    //store selected slice in state.
    setAssigned(slice)
  }

  //draw assigned pizza slices on plate, convert to image.
  //uses hidden canvas.
  const drawPizzaPlate = person => {
    const canvas = offscreenCanvasRef.current
    const ctx = canvas.getContext("2d")
    canvas.width = 150
    canvas.height = 150
    const cx = canvas.width / 2
    const cy = canvas.height / 2
    const radius = canvas.width / 2

    //draw pizza image to the canvas, clip out assigned slices for given person.
    const imgObj = new Image()

    imgObj.onload = () => {
      let width = canvas.width
      let naturalWidth = imgObj.naturalWidth
      let naturalHeight = imgObj.naturalHeight
      let ratio = naturalWidth / naturalHeight
      let height = width / ratio
      canvas.height = height
      ctx.drawImage(imgObj, 0, 0, width - 6, height - 6)
      ctx.save()

      person.pizza.map(slice => {
        ctx.beginPath()
        ctx.moveTo(cx, cy)
        ctx.arc(cx, cy, radius, slice.endAngle, slice.startAngle, false)
        ctx.lineTo(cx, cy)
        ctx.closePath()
        ctx.clip()
      })

      ctx.fillStyle = "#f5f2f2"
      ctx.fill()

      //convert to image, assigned image to given person.
      let image = canvas.toDataURL()
      let index = people.indexOf(person)
      images[index] = image
      setImages({ ...images })
      ctx.restore()
    }
    imgObj.src = pizzaImg
  }

  const assignSlice = name => {
    people.map(person => {
      if (assigned && person.name === name) {
        person.pizza.push(assigned)
        drawPizzaPlate(person)
        clipAssignedSlice(assigned)
      }
    })
    setAssigned(null)
  }

  //clip assigned slice out of the main image on top canvas
  const clipAssignedSlice = slice => {
    const canvas = canvasBottomRef.current
    const ctx = canvas.getContext("2d")
    const cx = canvas.width / 2
    const cy = canvas.height / 2
    const radius = canvasSize / 2

    ctx.save()
    ctx.beginPath()
    ctx.moveTo(cx, cy)
    ctx.arc(cx, cy, radius, slice.startAngle, slice.endAngle, false)
    ctx.lineTo(cx, cy)
    ctx.clip()
    ctx.fill()
    ctx.closePath()

    ctx.globalCompositeOperation = "source-out"
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    ctx.restore()
  }

  //reload page
  const startOver = () => {
    window.location.reload()
  }

  //undo last drawn line
  //remove it from lines array
  const undo = () => {
    lines.pop()
    const ctx = ctxT

    ctx.setLineDash([5, 10])
    ctx.lineWidth = 3
    ctx.strokeStyle = "black"

    const canvas = canvasTopRef.current
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    lines.map(line => {
      ctx.beginPath()
      ctx.moveTo(line.x, line.y)
      ctx.lineTo(cx, cy)
      ctx.closePath()
      ctx.stroke()
    })
  }

  //draw lines to show slices, draw opaque lines on slices that are already designed.
  const drawLines = () => {
    convertToAngles()
    const ctx = ctxT
    const canvas = canvasTopRef.current
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.lineWidth = 3

    angles.map(angle => {
      ctx.globalAlpha = 1
      ctx.strokeStyle = "black"
      ctx.setLineDash([5, 10])
      people.some(person => {
        if (person.pizza.length > 0) {
          person.pizza.some(pizzaObj => {
            if (pizzaObj.startAngle === angle || pizzaObj.endAngle === angle) {
              ctx.globalAlpha = 0.0
              ctx.strokeStyle = "white"
              ctx.setLineDash([])
            }
          })
        }
      })
      let x = cx + radius * Math.cos(angle)
      let y = cy + radius * Math.sin(angle)
      ctx.beginPath()
      ctx.moveTo(cx, cy)
      ctx.lineTo(x, y)
      ctx.closePath()
      ctx.stroke()
    })
    ctx.globalAlpha = 1
  }

  const openOverlay = () => {
    setToggleOverlay(true)
  }

  const slicePizza = () => {
    setStart(true)
    drawLines()
    const canvas = canvasTopRef.current
    canvas.onclick = ev => selectSlice(ev)
  }
  if (loading) {
    return <div></div>
  }

  const screenshot = async() => {
    const node = screenshotRef.current; 
    const canvas = await html2canvas(node, {
      onclone: (document) => {
        let elements = document.querySelectorAll(".plate_image");
        console.log(elements); 
        elements.forEach(element => {
            element.classList.add("plate_image--box_shadow");
        });
      },
      scale: 1,
    })
    let image = canvas.toDataURL("pizza/png", 1); 
    let a = document.createElement("a");
    a.href = image;
    a.setAttribute("download", "pizza/png");
    a.click();
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Fördela Pizzan Rättvist | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/demokratiska-samtal/vad-ar-rattvisa/pizza"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/demokratiska-samtal/vad-ar-rattvisa/pizza"
        ></meta>
        <meta property="og:title" content="Fördela Pizzan Rättvist"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-pizza">
        <div className="background_image"></div>
        <div className="background">
          {toggleOverlay && <Overlay onClick={() => setToggleOverlay(false)} />}
          <div className="container_lg">
            <div className="header_container">
              <div className="header-text">
                <p className="header_text__title">Fördela pizzan rättvist</p>
                <ol className="header_text__content">
                    <li>Rita upp hur du vill dela genom att klicka på pizzans kant.</li>
                    <li>När du är klar klicka på "dela pizza".</li>
                    <li>Klicka på en bit och sedan på den tallrik som du vill lägga den på.</li>
                    <li>Ladda ned din bild <span className="header_text__content__link" onClick={screenshot}>här</span> när du är klar</li>
                </ol>
              </div>
              {/*<p className="header_text">Fördela pizzan rättvist</p>*/}
              <button onClick={openOverlay} className="header_button">
                <span>Om barnen</span>
              </button>
            </div>
            <Controls
              undo={undo}
              startOver={startOver}
              previewSlices={() => previewSlices(2.5, "black")}
              selectSlice={selectSlice}
              slicePizza={slicePizza}
              start={start}
              openOverlay={openOverlay}
            />
            <div className={`row justify-content`} ref={screenshotRef}>
              {canvasSize > 395 && (
                <div className="col-lg-3 relative width-min-content">
                  <div className="plate_container plate_container_left">
                    <div>
                      <button
                        className="plate_button"
                        onClick={() => assignSlice("cilla")}
                        disabled={!start}
                      >
                        Cilla
                      </button>
                      <div
                        className={`plate_image`}
                        onClick={() => assignSlice("cilla")}
                      >
                        {images[0] && <img src={images[0]} alt="" />}
                      </div>
                    </div>
                    <div>
                      <button
                        className="plate_button"
                        onClick={() => assignSlice("doris")}
                        disabled={!start}
                      >
                        Doris
                      </button>
                      <div
                        className="plate_image"
                        onClick={() => assignSlice("doris")}
                      >
                        {images[1] && <img src={images[1]} alt="" />}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-6 col-xs-12">
                <div className="canvas_container row" ref={canvasContainerRef}>
                  <img className="canvas canvas_board" src={boardImg} />
                  <canvas
                    ref={canvasBottomRef}
                    className="canvas canvas_bottom"
                  />
                  <canvas
                    ref={canvasTopRef}
                    className="canvas canvas_top"
                    width={500}
                    height={500}
                  />
                  <canvas
                    ref={offscreenCanvasRef}
                    className="canvas canvas_hidden"
                  />
                </div>
              </div>
              {canvasSize > 395 && (
                <div className="col-lg-3 relative width-min-content">
                  <div className="plate_container plate_container_right">
                    <div>
                      <button
                        className="plate_button"
                        onClick={() => assignSlice("elmer")}
                        disabled={!start}
                      >
                        Elmer
                      </button>
                      <div
                        className="plate_image"
                        onClick={() => assignSlice("elmer")}
                      >
                        {images[2] && <img src={images[2]} alt="" />}
                      </div>
                    </div>

                    <div>
                      <button
                        className="plate_button"
                        onClick={() => assignSlice("amira")}
                        disabled={!start}
                      >
                        Amira
                      </button>
                      <div
                        className="plate_image"
                        onClick={() => assignSlice("amira")}
                      >
                        {images[3] && <img src={images[3]} alt="" />}
                      </div>
                    </div>
                    <div>
                      <button
                        className="plate_button"
                        onClick={() => assignSlice("ben")}
                        disabled={!start}
                      >
                        Ben
                      </button>
                      <div
                        className="plate_image"
                        onClick={() => assignSlice("ben")}
                      >
                        {images[4] && <img src={images[4]} alt="" />}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {canvasSize <= 395 && (
              <div>
                <div className="row plate_container_bottom">
                  <div>
                    <button
                      className="plate_button"
                      onClick={() => assignSlice("cilla")}
                      disabled={!start}
                    >
                      Cilla
                    </button>
                    <div
                      className="plate_image"
                      onClick={() => assignSlice("cilla")}
                    >
                      {images[0] && <img src={images[0]} alt="" />}
                    </div>
                  </div>
                  <div>
                    <button
                      className="plate_button"
                      onClick={() => assignSlice("doris")}
                      disabled={!start}
                    >
                      Doris
                    </button>
                    <div
                      className="plate_image"
                      onClick={() => assignSlice("doris")}
                    >
                      {images[1] && <img src={images[1]} alt="" />}
                    </div>
                  </div>
                  <div>
                    <button
                      className="plate_button"
                      onClick={() => assignSlice("elmer")}
                      disabled={!start}
                    >
                      Elmer
                    </button>
                    <div
                      className="plate_image"
                      onClick={() => assignSlice("elmer")}
                    >
                      {images[2] && <img src={images[2]} alt="" />}
                    </div>
                  </div>
                </div>
                <div className="row plate_container_bottom">
                  <div>
                    <button
                      className="plate_button"
                      onClick={() => assignSlice("amira")}
                      disabled={!start}
                    >
                      Amira
                    </button>
                    <div
                      className="plate_image"
                      onClick={() => assignSlice("amira")}
                    >
                      {images[3] && <img src={images[3]} alt="" />}
                    </div>
                  </div>
                  <div>
                    <button
                      className="plate_button"
                      onClick={() => assignSlice("ben")}
                      disabled={!start}
                    >
                      Ben
                    </button>
                    <div
                      className="plate_image"
                      onClick={() => assignSlice("ben")}
                    >
                      {images[4] && <img src={images[4]} alt="" />}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
