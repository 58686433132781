import React from "react"
import closeIcon from "../images/icons/dp-close.svg"

export default function Overlay({ onClick }) {
  return (
    <div className="overlay">
      <div className="overlay_content">
        <button className="overlay_content__close" onClick={onClick}>
          <img src={closeIcon} />
        </button>
        <div>
          <h4 className="overlay_content__title">Fördela pizzan rättvist</h4>

          <p>
            Er uppgift är att dela pizzan mellan barnen så ni tycker att det
            blir så rättvist som möjligt. Detta är vad ni vet om barnen
          </p>
          {/*<p>När vi ska dela någonting vill vi ofta att det ska bli rättvist. Men vad är egentligen en rättvis fördelning? < br />
                På den frågan kan det finnas flera svar. Vi kan till exempel dela efter behov eller efter förtjänst. Eller så kan vi tänka att det är mest rättvist att dela precis lika. Finns det andra sätt att fördela rättvist? Kan det vara olika i olika situationer?</p>*/}

          <h5 className="overlay_content__subtitle">
            Barnen på ön heter Amira, Ben, Cilla, Doris och Elmer{" "}
          </h5>

          <p>
            Amira är äldst och tar hand om de andra barnen. Hon börjar bygga ett
            litet hus som de kan bo i tills de blir räddade.
          </p>

          <p>
            Ben är inte lika stark som Amira men hjälper till så gott han kan.
            Det var Ben som hittade pizzan. Pizzan var hans matsäck på resan.
          </p>

          <p>
            Men Cilla har det värre. Under olyckan med båten bröt hon benet och
            nu har hon så ont att hon är alldeles blek i ansiktet. Cilla ligger
            ner och behöver till och med hjälp av Amira för att ta sig till
            huset för att sova på natten.
          </p>

          <p>
            Doris är sur. Hon skriker till dom andra att det är ett dåligt hus
            dom bygger. Inte alls lika fint som det som hon har hemma. Hon river
            bort några pinnar från huset och kastar dom i vattnet. Efter det går
            hon iväg och tjurar på stranden.
          </p>

          <p>
            Elmer är minst av alla barnen och han är jättehungrig. Att simma in
            till stranden under olycksnatten var så ansträngande för honom att
            han knappt orkar stå upp nu. Han gråter och saknar sin familj."
          </p>
        </div>
      </div>
    </div>
  )
}
